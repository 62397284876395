import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'
import { setLightness } from 'polished'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import moment from 'moment'

import SEO from '../components/header/SEO'
import AuthorCard from '../components/AuthorCard'
import Footer from '../components/Footer'
import SiteNav from '../components/header/SiteNav'
import PostCard from '../components/PostCard'
import PostContent from '../components/PostContent'
import PostFullFooter from '../components/PostFullFooter'
import ReadNextCard from '../components/ReadNextCard'
import Wrapper from '../components/Wrapper'
import IndexLayout from '../layouts'
import { colors } from '../styles/colors'
import { inner, outer, SiteHeader, SiteMain } from '../styles/shared'

const PostTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`

export const PostFull = css`
  position: relative;
  z-index: 50;
`

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`

export const PostFullHeader = styled.header`
  margin: 0 auto;
  padding: 6vw 3vw 3vw;
  max-width: 1040px;
  text-align: center;

  @media (max-width: 500px) {
    padding: 14vw 3vw 10vw;
  }
`

const PostFullMeta = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.midgrey};
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 1.2rem;
    line-height: 1.3em;
  }
`

const PostFullMetaDate = styled.time`
  color: ${colors.blue};
`

export const PostFullTitle = styled.h1`
  margin: 0;
  color: ${setLightness('0.05', colors.darkgrey)};
  @media (max-width: 500px) {
    font-size: 2.9rem;
  }
`

const PostFullImage = styled.figure`
  margin: 0 -10vw -165px;
  height: 800px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;

  @media (max-width: 1170px) {
    margin: 0 -4vw -100px;
    height: 600px;
    border-radius: 0;
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 350px;
  }
`

const DateDivider = styled.span`
  display: inline-block;
  margin: 0 6px 1px;
`

const ReadNextFeed = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
`

const PageTemplate = props => {
  const post = props.data.contentfulPost
  let width = ''
  let height = ''
  if (post.heroImage && post.heroImage.fluid) {
    width = post.heroImage.fluid.sizes.split(', ')[1].split('px')[0]
    height = String(Number(width) / post.heroImage.fluid.aspectRatio)
  }

  const imgStyle = {}

  if (post.heroImage && post.heroImage.fluid.aspectRatio > 2) imgStyle.objectFit = 'contain'

  const {
    pageContext: { tags, prev, next },
    data,
  } = props

  return (
    <IndexLayout className="post-template">
      <SEO article={{ ...post, height, width }} />
      <Wrapper css={PostTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            {/* TODO: no-image css tag? */}
            <article css={[PostFull, !post.heroImage && NoImage]}>
              <PostFullHeader>
                <PostFullMeta>
                  <PostFullMetaDate dateTime={post.publishDate}>
                    {moment(post.publishDate)
                      .local()
                      .format('MMMM Do YYYY')}
                  </PostFullMetaDate>
                  {post.tags && post.tags.length > 0 && (
                    <>
                      <DateDivider>/</DateDivider>
                      <Link to={`/tags/${_.kebabCase(post.tags[0])}/`}>{post.tags[0]}</Link>
                    </>
                  )}
                </PostFullMeta>
                <PostFullTitle>{post.title}</PostFullTitle>
              </PostFullHeader>

              {post.heroImage && post.heroImage.fluid && (
                <PostFullImage>
                  <Img imgStyle={imgStyle} style={{ height: '100%' }} fluid={post.heroImage.fluid} alt={post.title} />
                </PostFullImage>
              )}
              <PostContent htmlAst={post.body.childMarkdownRemark.htmlAst} tags={tags} />

              <PostFullFooter>
                <AuthorCard author={post.author} />
              </PostFullFooter>
            </article>
          </div>
        </main>

        {/* Links to Previous/Next posts */}
        <aside className="read-next" css={outer}>
          <div css={inner}>
            <ReadNextFeed>
              {data.allContentfulPost && (
                <ReadNextCard
                  tags={post.tags}
                  relatedPosts={data.allContentfulPost.edges.filter(edge => edge.node.slug !== post.slug)}
                />
              )}

              {prev && <PostCard post={prev} />}
              {next && <PostCard post={next} />}
            </ReadNextFeed>
          </div>
        </aside>
        <Footer />
      </Wrapper>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query($slug: String, $tag: String) {
    logo: file(relativePath: { eq: "img/logo.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      heroImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      publishDate
      tags
      body {
        childMarkdownRemark {
          excerpt
          htmlAst
        }
      }
      author {
        name
        image {
          fluid(maxWidth: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulPost(filter: { tags: { eq: $tag } }) {
      edges {
        node {
          title
          slug
          heroImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          publishDate
          tags
          body {
            childMarkdownRemark {
              excerpt
              timeToRead
            }
          }
          author {
            name
            image {
              fluid(maxWidth: 90) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
