import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import { colors } from '../styles/colors'
import { AuthorProfileImage } from '../styles/shared'

const AuthorCardSection = styled.section`
  display: flex;
  align-items: center;
`

const AuthorCardName = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 2rem;

  a {
    color: ${colors.darkgrey};
    font-weight: 700;
  }

  a:hover {
    text-decoration: none;
  }
`

const AuthorCardContent = styled.section`
  p {
    margin: 0;
    color: ${colors.midgrey};
    line-height: 1.3em;
  }
`

const AuthorCard = ({ author }) => (
  <AuthorCardSection>
    <Img css={AuthorProfileImage} fluid={author.image.fluid} alt={author.name} />
    <AuthorCardContent>
      <AuthorCardName>
        <Link to="/about-me">{author.name}</Link>
      </AuthorCardName>
    </AuthorCardContent>
  </AuthorCardSection>
)

AuthorCard.propTypes = {
  author: PropTypes.object.isRequired,
}

export default AuthorCard
